import React, { useState, useEffect } from "react";

import { Button } from 'primereact/button';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';

import { datetimeBody, integratedBody, timeStampStr } from "../lib/dataTableLib";

export function Transactions(props) {
    const [movements] = useState(props.movs);
    const [ filters, setFilters ] = useState(null);
    const [ globalFilterValue, setGlobalFilterValue] = useState('');

    useEffect(() => {
        initFilters();
    }, []);
    // ****************************************************************
    // for search stuff -  for Datatables
    // ****************************************************************
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters.global.value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const clearFilter = () => {
        initFilters();
    }; 
    const initFilters = () => {
        setFilters({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        setGlobalFilterValue('');
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button type="button" icon="pi pi-filter-slash" label="Limpar" className="p-button-outlined" onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Palavras a pesquisar" />
                </span>
            </>
        )
    };
    

    return (
        <>
            <Toolbar className="mb-10" right={rightToolbarTemplate}></Toolbar>

            <DataTable
                value={movements} stripedRows 
                scrollable scrollHeight="700px"
                dataKey="movementId" filters={filters}
                emptyMessage="Não encontramos nenhum Movimento."
            >
                <Column field="movementId" header="Data Movimento"
                        body={(rowdata) => datetimeBody(rowdata.movementId)}
                    style={{ minWidth: '180px' }} sortable ></Column>
                <Column field="siteId" header="Site-Local"></Column>
                <Column field="partnerId" header="Associado" align="right"></Column>
                <Column field="cardCodeId" header="Cartão" align="right"></Column>
                <Column
                    field="qtyConsumed"
                    header="Consumido" align="right"
                    body={event => event.qtyConsumed * (event?.factor || 10)}
                ></Column>
                <Column
                    field="qtyCorrected"
                    header="Acertos" align="right"
                    body={event => event.qtyCorrected * (event?.factor || 10)}
                ></Column>
                <Column field="fillTime" header="T.Ench(seg)"
                    body={(rowdata) => timeStampStr(rowdata.fillTime)}
                ></Column>
                <Column field="isIntegrated" header="Integ" body={integratedBody}></Column>
            </DataTable>
        </>
    );
}
