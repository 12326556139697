import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";

import { useAppContext } from "../lib/contextLib";
// import { onError } from "../lib/errorLib";
import "./Home.css";


export default function Home() {
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function renderCardsList() {
    return (
      <div className="lander">
        <h3>Teremos os gráficos do sistema, consumos mensais globais e por sites!</h3>
        <div>
          <Link to="/partners" className="btn btn-info btn-lg">
            Associados
          </Link>
        </div>
      </div>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>CD Baldios</h1>
        <p>Aplicação de controle de enchimento da Murteira, Pé da Pedreira e Valverde.</p>
        <br />
        <div>
          <Link to="/login" className="btn btn-info btn-lg">
            Login
          </Link>
          {/* <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link> */}
        </div>
      </div>
    );
  }

  function renderCards() {
    return (
      <div className="cards">
        <ListGroup>
          {!isLoading && renderCardsList()}
        </ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderCards() : renderLander()}
    </div>
  );
}
