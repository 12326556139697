import React, { useState, useEffect } from "react";

import { Button } from 'primereact/button';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';

import { datetimeBody } from "../lib/dataTableLib";

export function Actions(props) {
    const [ logActions ] = useState(props.actions);
    const [ filters, setFilters ] = useState(null);
    const [ globalFilterValue, setGlobalFilterValue] = useState('');

    useEffect(() => {
        initFilters();
    }, []);

    // ****************************************************************
    // for search stuff -  for Datatables
    // ****************************************************************
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters.global.value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const clearFilter = () => {
        initFilters();
    }; 
    const initFilters = () => {
        setFilters({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        setGlobalFilterValue('');
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button type="button" icon="pi pi-filter-slash" label="Limpar" className="p-button-outlined" onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Palavras a pesquisar" />
                </span>
            </>
        )
    };
    

    return (
        <>
            <Toolbar className="mb-10" right={rightToolbarTemplate}></Toolbar>

            <DataTable
                value={logActions} stripedRows 
                scrollable scrollHeight="700px"
                dataKey="movementId" filters={filters}
                emptyMessage="Não encontramos nenhum logCommunicações."
            >
                <Column field="logId" header="Data Log"
                        body={(rowdata) => datetimeBody(rowdata.logId)}
                    style={{ minWidth: '180px' }} sortable ></Column>
                <Column field="siteId" header="Site-Local"></Column>
                <Column field="action" header="Tipo Ação"></Column>
                <Column field="cardCodeId" header="Cartão"></Column>
                <Column field="returnCode" header="ReturnCode" align="center"></Column>
                <Column field="errors" header="Mensagem"></Column>
            </DataTable>
        </>
    );
}
