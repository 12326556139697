import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

import { Dialog } from 'primereact/dialog';

import { Actions } from '../../components/LogActions';
import { LocalStorageState } from "../../lib/contextLib";
import { renderProgressSpinner } from "../../lib/dataTableLib";

export function DialogLogActions(props) {
    const [logActions, setLogActions] = useState();
    const [site] = useState(props.site);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
        loadSitesLogActions();
        setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    async function loadSitesLogActions() {
        await API.get("baldios", "/logactions", {
        queryStringParameters: {
            contractId: site.contractId,
            siteId: site.siteId,
            userId: LocalStorageState('UserEmail', 'read'),
        }
        })
        .then(function (response) {
            if (response.data.length) {
                // console.log("response", response);
                if (response.statusCode === 200) {
                    props.messages("info", "leitura logComms", "logComms de " + site.shortName + " lidos.");
                } else {
                    props.messages(
                        "error",
                        "leitura logComms",
                        "statusCode:" + response.statusCode + " | Message: " + (response?.errors || "Sem erros.")
                    );   
                }
            } 
            // console.log("load data from server", response.data);
            setLogActions(response.data);
        })
        .catch(function (error) {
            if (error.response) {
                props.messages("error", "leitura logComms", "response:"+error.response);
            } else if (error.request) {
                props.messages("error", "leitura logComms", "PF informar que o server está em baixo");
            } else {
                props.messages("error", "leitura logComms", "message:"+error.message);          
            }
                props.messages("error", "leitura logComms", "Url: " + error.config.url);
        });     
    }

    function renderLogActions() {
        return (
            <div>
                {!loading && <Dialog
                    visible={true} modal={true} resizable={false} 
                    style={{ width: '1100px' }} className="p-fluid"
                    header={"Site/Local: " + site.shortName}
                    onHide={() => props.hideDialog(false)}
                >
                    {logActions && <Actions actions={logActions}></Actions>}
                </Dialog>}
            </div>    
        )
    }

    return ((!loading && logActions && renderLogActions()) || (loading && renderProgressSpinner()))
}
