import { useContext, createContext } from "react";

export const AppContext = createContext(null);
export function useAppContext() {
  return useContext(AppContext);
}

export function LocalStorageState(
  key,
  action = 'destroy',
  defaultValue = '',
) {
  switch (action) {
    case 'assign':
      window.localStorage.setItem(key, defaultValue);
      return defaultValue;
    case 'destroy':
      return window.localStorage.removeItem(key);
    case 'read':
      return window.localStorage.getItem(key);
    default:
      return;
  }
}
