import React, { useState, useEffect, useRef } from "react";

import { API } from "aws-amplify";

import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';

import { useAppContext, LocalStorageState } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import {
  // header,
  lockedBody,
  renderProgressSpinner,
} from "../../lib/dataTableLib";
import './LisPartners.css';
import { DialogPartner } from "./DetailPartner";
import { DialogCards } from "./CardsPartner";
import { DialogMovements } from "./Movements";

export default function Home() {
    const toast = useRef(null);
    const { isAuthenticated } = useAppContext();
    const [ lockedPartnerDialog, setLockedPartnerDialog ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ partner, setPartner ] = useState();
    const [ partnerNew, setPartnerNew ] = useState();
    const [ cardsDialog, setCardsDialog ] = useState();
    const [ partnerDialog, setPartnerDialog ] = useState();
    const [ movementsDialog, setMovementsDialog ] = useState();
    const [ partners, setPartners ] = useState();

    const [ filters, setFilters ] = useState(null);
    const [ globalFilterValue, setGlobalFilterValue] = useState('');

    // ----------------------------------------------------------------
    const actionBodyTemplate = (rowData) => {
        return (
        <React.Fragment>
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editPartner(rowData)} />
            <Button icon="pi pi-lock" className="p-button-rounded p-button-warning" onClick={() => confirmLockedPartner(rowData)} />
            <Button icon="pi pi-credit-card" className="p-button-rounded p-button-success" onClick={() => cardsPartner(rowData)} />
            <Button icon="pi pi-book" className="p-button-rounded p-button-secondary" onClick={() => movementsPartner(rowData)} />
        </React.Fragment>
        );
    }
    // ----------------------------------------------------------------
    function openNew () {
        setPartnerNew(true);
        setPartner(emptyPartner);
        setPartnerDialog(true);
    }
    // ----------------------------------------------------------------
    function editPartner (partner) {
        setPartnerNew(false);
        setPartner({ ...partner });
        setPartnerDialog(true);
    }
    // ----------------------------------------------------------------
    function cardsPartner (partner) {
        setPartner(partner);
        setCardsDialog(true);
    }
    // ----------------------------------------------------------------
    function movementsPartner (partner) {
        setPartner(partner);
        setMovementsDialog(true);
    }
    // ----------------------------------------------------------------
    //  change status of partner isLocked 
    // ----------------------------------------------------------------
    function lockedPartner(data) {
        API.patch("baldios", "/partners/" + partner.partnerId, {
            body: {
                ...data,
                contractId: partner.contractId,
                userId: LocalStorageState('UserEmail', 'read')
            }
            })
            .then(function () {
            hideDialog(true);
        })
        .catch(error => {
            if (error.request) {
            showMsgToast("error", "bloquear associados", "PF informar que o server está em baixo");
            } else if (error.response) {
            showMsgToast("error", "bloquear associados", "response "+error.response);
            } else {
            showMsgToast("error", "bloquear associados", "message "+error.message);          
            }
            showMsgToast("error", "bloquear associados", "Url: " + error.config.url);
        });
    }
    
    // ----------------------------------------------------------------
    const confirmLockedPartner = (partner) => {
        setPartner(partner);
        setLockedPartnerDialog(true);
    };
    // ----------------------------------------------------------------
    function showMsgToast(type, sum, message) {
        if (type !== "error") {
        toast.current.show([{ severity: type, summary: sum, detail: message, life: 3000 }]);
        } else {
        toast.current.show([{ severity: type, summary: sum, detail: message, sticky: true}]);
        }
    }
    
    let emptyPartner = {
        partnerId: '',
        contractId: process.env.REACT_APP_CONTRACT,
        fullName: '', 
        shortName: '', 
        phone: '',
        email: '', 
        street: '', 
        zip_code: '', 
        zip_local: '', 
        qtyAllowed: -1,     // month limit for filling
        qtyDayly: -1,       // day limit for filling
        qtyFilled: 0,       // consumed total ever
        qtyConsumed: 0,     // consumed actual month
        lastConsumed: 0,    // date of last consumed
        isLocked: false,
        version: 0,
    };
    // ################################################################
    // dialogs auxliars 
    function hideDialog(status) {
        setCardsDialog(false);
        setMovementsDialog(false);
        setPartnerNew(false);
        setPartnerDialog(false);
        setLockedPartnerDialog(false);
        setIsLoading(status);
    }
    const lockedPartnerDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={() => lockedPartner(partner)} />
        </React.Fragment>
    );

    // ****************************************************************
    // for search stuff -  for Datatables
    // ****************************************************************
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters.global.value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const clearFilter = () => {
        initFilters();
    }; 
    const initFilters = () => {
        setFilters({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        setGlobalFilterValue('');
    };
    // ----------------------------------------------------------------
    const leftToolbarTemplate = () => {
        return (
        <React.Fragment>
            <Button label="Adicionar Associado" icon="pi pi-plus" className="p-button-success p-button-rounded" onClick={openNew} />
        </React.Fragment>
        )
    }
    const rightToolbarTemplate = () => {
        return (
        <div className="flex justify-content-between">
            <Button type="button" icon="pi pi-filter-slash" label="Limpar" className="p-button-outlined" onClick={clearFilter} />
            <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Palavras a pesquisar" />
            </span>
        </div>
        )
    };
    
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    useEffect(() => {
        async function onLoad() {
        if (!isAuthenticated) {
            return;
        }
        try {
            if (isLoading) {
            const partners = await loadPartners();
            setPartners(partners);
            setPartner({ ...partners[0] });
            }
        } catch (e) {
            onError(e);
        }
        initFilters();
        setIsLoading(false);
        }
        onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isLoading]);

    // ******************************************************************
    async function loadPartners() {
        const myInit = {
        body: {
            contractId: process.env.REACT_APP_CONTRACT,
            userId: LocalStorageState('UserEmail', 'read'),
        },
        };
        return await API.post("baldios", "/partners", myInit)
        .then((result) => {
            return result;
        })
        .catch(error => { 
            if (error.request) {
            showMsgToast("error", "ler associados", "PF informar que o server está em baixo");
            } else if (error.response) {
            showMsgToast("error", "ler associados", "response "+error.response);
            } else {
            showMsgToast("error", "ler associados", "message "+error.message);          
            }
            showMsgToast("error", "ler associados", "Url: " + error.config.url);
        });
    }

    function renderPartnersList() {
        return (
        <>
            <Toast ref={toast} />        
            <Toolbar className="mb-10" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

            <DataTable value={partners} stripedRows
                scrollable scrollHeight="700px"
                dataKey="partnerId" filters={filters}
                emptyMessage="Não encontramos nenhum Associado."
            >
                <Column hidden field="contractId" header="Contrato"></Column>
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }} frozen={true}></Column>
                <Column field="fullName" header="Nome Completo" style={{ width: '400px' }} sortable frozen></Column>
                <Column field="partnerId" header="Identificação" style={{ minWidth: '100px' }} sortable ></Column>
                <Column field="phone" header="Contatos" style={{ minWidth: '100px' }}></Column>
                <Column field="isLocked" header="Status" align="center" body={lockedBody} style={{ minWidth: '100px' }}></Column>
                <Column field="qtyAllowed" header="Qtd Mensal" align="right" style={{ minWidth: '100px' }}></Column>
                <Column field="qtyDayly" header="Qtd Diário" align="right" style={{ minWidth: '100px' }}></Column>
                <Column field="qtyConsumed" header="Consumido" align="right" style={{ minWidth: '100px' }}></Column>
                <Column field="qtyFilled" header="Total" align="right" style={{ minWidth: '100px' }}></Column>
                <Column field="email" header="Email" style={{ minWidth: '500px' }}></Column>
            </DataTable>

            {partnerDialog && <DialogPartner
            hideDialog={hideDialog}
            partner={partner}
            partnerNew={partnerNew}
            messages={showMsgToast}
            />}

            {cardsDialog && <DialogCards
            hideDialog={hideDialog}
            partner={partner}
            messages={showMsgToast}
            />}

            {movementsDialog && <DialogMovements
            hideDialog={hideDialog}
            partner={partner}
            messages={showMsgToast}
            />}

            <Dialog visible={lockedPartnerDialog}
            style={{ width: '450px' }}
            header="Bloqueio/Desbloqueio" modal
            footer={lockedPartnerDialogFooter}
            onHide={() => hideDialog(false)}
            >
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                {partner && <span>Tem a certeza que pretende {partner.isLocked ?"desbloquear" : "bloquear"} associado <b>{partner.shortName}</b>?</span>}
            </div>
            </Dialog>
        </>
        );
    }

    function renderLander() {
        return (
        <div className="lander">
            <h1>CD Baldios</h1>
            <p>Loading associados from cloud.</p>
        </div>
        );
    }

    function renderPartners() {
        return (
        <div className="partners">
            {(!isLoading && renderPartnersList()) || (isLoading && renderProgressSpinner())}
        </div>
        );
    }

    return (
        <div className="Home">
        {isAuthenticated ? renderPartners() : renderLander()}
        </div>
    );
}
