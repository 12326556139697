import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

// import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';

import { LocalStorageState } from "../../lib/contextLib";

/* ------------------------------------------------------------------------------------------------
                  new or update site
------------------------------------------------------------------------------------------------ */
export function DialogSite(props) {
    const [siteNew, setSiteNew] = useState();
    const [site, setSite] = useState(props.site);

    useEffect(() => {
        setSiteNew(props.siteNew);
    }, [props.siteNew]);

    // ----------------------------------------------------------------
    function validateSite() {
        const _site = site;
        return _site && _site.shortName && _site.qtyAllowed && _site.qtyDayly && _site.tim001 && _site.tim002; 
    }  
    
    // ----------------------------------------------------------------
    function onInputChange(e, name, options) {
        let val = (e.target && e.target.value) || '';
        let _site = { ...site };
        if (options){
            switch (options.toLowerCase()) {
                case 'upper':
                    val = val.toUpperCase();
                    break;
                case 'lower':
                    val = val.toLowerCase();
                    break;
                default:
                    break;
            }
        }
        _site[`${name}`] = val;
        setSite(_site);
    }
    // ----------------------------------------------------------------
    function onInputMaskChange(e, name) {
        let val = (e.value) || '';
        let _site = { ...site };
        _site[`${name}`] = val;
        setSite(_site);
    }
    // ----------------------------------------------------------------
    const siteDialogFooter = (
        <React.Fragment>
            <Button label="Fechar" icon="pi pi-times" className="p-button-rounded p-button-warning" onClick={() => props.hideDialog(false)} />
            {validateSite() && <Button label="Salvar" icon="pi pi-check" className="p-button-rounded p-button-danger" onClick={saveSite} />}
        </React.Fragment>
    );  
    // ----------------------------------------------------------------
    async function saveSite () {
        const myInit = {
            body: { ...site, userId: LocalStorageState('UserEmail', 'read') },
        };
        if (siteNew) {
            await API.post("baldios", "/sites/add", myInit)
                .then(function () {
                    props.hideDialog(true);
                })
                .catch(function (error) {
                    if (error.request) {
                        props.messages("error", "adicionar site-local", "PF informar que o server está em baixo");
                    } else if (error.response) {
                        props.messages("error", "adicionar site-local", "response"+error.response);
                    } else {
                        props.messages("error", "adicionar site-local", "message"+error.message);          
                    }
                        props.messages("error", "adicionar site-local", "Url: " + error.config.url);
                });
        } else {
            API.put("baldios", "/sites/" + site.siteId, myInit)
                .then(function (response) {
                    console.log(response);
                    if (response.statusCode !== 200) {
                        props.messages("error", "guardar site-local", response.errors);
                    } else {
                        props.hideDialog(true);
                    }
                })
                .catch(function (error) {
                    if (error.request) {
                        props.messages("error", "guardar site-local", "PF informar que o server está em baixo");
                    } else if (error.response) {
                        props.messages("error", "guardar site-local", "response"+error.response);
                    } else {
                        props.messages("error", "guardar site-local", "message"+error.message);          
                    }
                    props.messages("error", "guardar site-local", "Url: " + error.config.url);
                });
        }
    };

    return (site &&
        <Dialog 
        visible={true} modal={true} resizable={false} 
        style={{ width: '450px' }} header="Detalhes Site-Local"
        className="p-fluid" footer={siteDialogFooter}
        closable={false}
        >
            <div className="field">
                <label htmlFor="id">Endereço MAC</label>
                {siteNew && <InputMask id="id"  required autoFocus
                value={site.siteId} onChange={(e) => onInputMaskChange(e, 'siteId')}
                />}
                {!siteNew && <InputText id="id" value={site.siteId} disabled />}
            </div>

            <div className="field">
                <label>Nome</label>
                <InputText placeholder="nome apelido" value={site.shortName}
                onChange={(e) => onInputChange(e, 'shortName', 'upper')}
                required />
            </div>

            <div className="field">
                <label htmlFor="factor">Factor Multiplicativo</label>
                <InputNumber id="factor" value={site.factor}
                    onValueChange={(e) => onInputChange(e, 'factor')}
                    mode="decimal" min={1} max={99}
                    minFractionDigits={3}
                    required
                />
            </div>

            <div className="field">
                <label htmlFor="qtyAllowed">Qtd Mensal</label>
                <InputNumber id="qtyAllowed" value={site.qtyAllowed}
                    onValueChange={(e) => onInputChange(e, 'qtyAllowed')}
                    mode="decimal" integeronly min={-1} max={999999}
                    required
                />
            </div>

            <div className="field">
                <label htmlFor="qtyDayly">Qtd Diária</label>
                <InputNumber id="qtyDayly" value={site.qtyDayly}
                    onValueChange={(e) => onInputChange(e, 'qtyDayly')}
                    mode="decimal" integeronly min={-1} max={999999}
                    required
                />
            </div>
                
            <div className="field">
                <label htmlFor="tim001">[T001] Abrir EV depois Bomba</label>
                <InputNumber id="tim001" value={site.tim001}
                    onValueChange={(e) => onInputChange(e, 'tim001')}
                    mode="decimal" min={-1} max={99}
                    minFractionDigits={3}
                    required
                />
            </div>

            <div className="field">
                <label htmlFor="tim002">[T002] Desligar Bomba depois EV</label>
                <InputNumber id="tim002" value={site.tim002}
                    onValueChange={(e) => onInputChange(e, 'tim002')}
                    mode="decimal" min={-1} max={99}
                    minFractionDigits={3}
                    required
                />
            </div>

            <div className="field">
                <label htmlFor="tim003">[T003] Auto reset enchimento</label>
                <InputNumber id="tim003" value={site.tim003}
                    onValueChange={(e) => onInputChange(e, 'tim003')}
                    mode="decimal" min={-1} max={999}
                    minFractionDigits={3}
                    required
                />
            </div>

            <div className="field">
                <label htmlFor="tim004">[T004] Espera para final enchimento</label>
                <InputNumber id="tim004" value={site.tim004}
                    onValueChange={(e) => onInputChange(e, 'tim004')}
                    mode="decimal" min={-1} max={99}
                    minFractionDigits={3}
                    required
                />
            </div>

            <div className="field">
                <label htmlFor="tim005">[T005] Desligar LEDs</label>
                <InputNumber id="tim005" value={site.tim005}
                    onValueChange={(e) => onInputChange(e, 'tim005')}
                    mode="decimal" integeronly min={600} max={3600}
                    required
                />
            </div>
        </Dialog>
    )
}
