import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Toolbar } from 'primereact/toolbar';

import { lockedBody } from "../../lib/dataTableLib";
import { LocalStorageState } from "../../lib/contextLib";
import { DialogLogActions } from "./LogActions";

/* ----------------------------------------------------------------
                  view, add, update or remove cards
------------------------------------------------------------------- */
export function DialogCards(props) {
    const [partner] = useState(props.partner);
    const [card, setCard] = useState();
    const [cardNew, setCardNew] = useState();
    const [cardUpdate, setCardUpdate] = useState();
    const [cards, setCards] = useState();
    const [loadCards, setLoadCards] = useState(true);
    const [logCommsDialog, setLogCommsDialog] = useState();

    useEffect(() => {
        if (loadCards) {
        loadPartnerCards();
        }
        setLoadCards(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadCards]);
    
    async function loadPartnerCards() {
        // console.log(partner);
        await API.get("baldios", "/partners/cards/"+partner.partnerId, {
        queryStringParameters: {
            contractId: partner.contractId,
            },
            // mode: 'cors'    
        })
        .then(function (response) {
            if (response.data.length) {
            props.messages("info", "leitura cartões", "cartões do associado "+partner.shortName+" lidos.");
            } 
            setCards(response.data);
        })
        .catch(function (error) {
            if (error.response) {
            props.messages("error", "leitura cartões", "response:"+error.response);
            } else if (error.request) {
            props.messages("error", "leitura cartões", "PF informar que o server está em baixo");
            } else {
            props.messages("error", "leitura cartões", "message:"+error.message);          
            }
            props.messages("error", "leitura cartões", "Url: " + error.config.url);
        });     
    }

    const validateCard = () => {
        return true;
        };
        
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editCard(rowData)} />
                <Button icon="pi pi-lock" className="p-button-rounded p-button-warning" onClick={() => confirmLockedCard(rowData)} />
                <Button icon="pi pi-database" className="p-button-rounded p-button-secondary" onClick={() => logCommsSite(rowData)} />
        
            </React.Fragment>
        );
    }
    function editCard(rowdata) {
        setCard(rowdata);
        setCardNew(false);
        setCardUpdate(true);
    }
    // ----------------------------------------------------------------
    function logCommsSite (card) {
        setCard(card);
        setLogCommsDialog(true);
    }
    
    async function confirmLockedCard(rowdata) {
        await API.patch(
        "baldios",
        "/cards/" + rowdata.cardCodeId,
        {
            body: {
            ...rowdata,
            userId: LocalStorageState('UserEmail', 'read')
            }
        })
        .then(() => {
            setLoadCards(true);
        })
        .catch();
    }
    function hideCard(value) {
        setCardNew(false);
        setCardUpdate(false);
        setLoadCards(value);
        setLogCommsDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
        <React.Fragment>
            <Button label="Novo Cartão" icon="pi pi-plus" className="p-button-rounded p-button-success" onClick={openNew} />
        </React.Fragment>
        )
    }
    async function openNew() {
        setCard({
        partnerId: partner.partnerId,
        contractId: partner.contractId,
        associateId: "",
        cardCodeId: "",
        shortName: "",
        qtyAllowed: -1,
        qtyDayly: -1,
        version: 0,
        isLocked: false,
        qtyConsumed: 0,
        lastConsumed: 0,
        });
        setCardNew(true);
        setCardUpdate(true);
    }
    
    const rightToolbarTemplate = (
        <React.Fragment>
            <Button label="Fechar" icon="pi pi-times" className="p-button-rounded p-button-warning" onClick={() =>props.hideDialog(false)} />
            {false && validateCard() && <Button label="Save" icon="pi pi-check" className="p-button-rounded p-button-danger" onClick={saveCard} />}
        </React.Fragment>
    );
    async function saveCard() {
        props.hideDialog(false);
    }

    return (
        <div>
            {!loadCards && <Dialog
                visible={true} modal={true} resizable={false} 
                style={{ width: '1100px' }} className="p-fluid"
                header={"Cartões Associado " + partner.shortName}
                closable={true}
            >
                {cards && <DataTable value={cards}
                stripedRows scrollable scrollHeight="500px" scrollDirection="both"
                dataKey="cardCodeId"
                emptyMessage="Não encontramos nenhum cartão do associado."
                >
                <Column hidden field="contractId" header="Contrato"></Column>
                <Column hidden field="partnerId" header="Identificação"></Column>
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }} frozen={true}></Column>
                <Column field="associateId" header="Número Sócio"  style={{ minWidth: '200px' }}frozen></Column>
                <Column field="cardCodeId" header="Código Cartão"  style={{ minWidth: '200px' }}></Column>
                <Column field="shortName" header="Nome Cartão" style={{minWidth:'200px'}}></Column>
                <Column field="isLocked" header="Status" body={lockedBody}></Column>
                <Column field="qtyAllowed" header="Mensal" align="right" style={{ minWidth: '100px' }}></Column>
                <Column field="qtyDayly" header="Diário" align="right" style={{ minWidth: '100px' }}></Column>
                <Column field="qtyConsumed" header="Consumido" align="right" style={{ minWidth: '100px' }}></Column>
                <Column field="qtyFilled" header="Total" align="right" style={{ minWidth: '100px' }}></Column>
                </DataTable>}
                <Toolbar className="mb-10" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            </Dialog>}

            {cardUpdate && <DialogCardUpdate
                card={card} messages={props.messages} hideDialog={hideCard}
                new={cardNew} name={props.partner.shortName}
            />}
            {logCommsDialog && <DialogLogActions
                hideDialog={hideCard}
                card={card}
                messages={props.messages}
            />}    


        </div>    
    )
}

/* ----------------------------------------------------------------
      new or update card
---------------------------------------------------------------- */
function DialogCardUpdate(props) {
    const [card, setCard] = useState(props.card);

    function onInputChange(e, name, options) {
        let val = (e.target && e.target.value) || '';
        let _card = { ...card };
        if (options) {
        switch (options.toLowerCase()) {
            case 'upper':
            val = val.toUpperCase();
            break;
            case 'lower':
            val = val.toLowerCase();
            break;
            default:
            break;
        }
        }
        _card[`${name}`] = val;
        setCard(_card);
    }

    const cardDialogFooter = (
        <React.Fragment>
        <Button label="Fechar" icon="pi pi-times" className="p-button-rounded p-button-warning" onClick={() => props.hideDialog(false)} />
        {validateCard() && <Button label="Salvar" icon="pi pi-check" className="p-button-rounded p-button-danger" onClick={saveCard} />}
        </React.Fragment>
    );
    async function saveCard() {
        if (props.new) {
        await API.post(
            'baldios',
            '/cards/add',
            {
            body: {
                ...card,
                userId: LocalStorageState('UserEmail', 'read')
            }
            })
            .then(response => {
            if (response.statusCode < 300) {
                props.messages("info", "adicionar cartão", "cartão " + card.cardCodeId + " adicionado.");
                props.hideDialog(true);
            } else {
                props.messages(
                "error",
                "adicionar cartão",
                "Error: " + response.statusCode + " " + response?.error
                );
            }
            })
            .catch(error => {
            if (error.request) {
                props.messages("error", "adicionar cartão", "PF informar que o server está em baixo");
            } else if (error.response) {
                props.messages("error", "adicionar cartão", "response" + error.response);
            } else {
                props.messages("error", "adicionar cartão", "message" + error.message);
            }
            props.messages("error", "adicionar cartão", "Url: " + error.config.url);
            });
        } else {
        await API.put('baldios', '/cards/' + card.cardCodeId, {
            body: {
            ...card,
            userId: LocalStorageState('UserEmail', 'read')
            }
        })
            .then(response => {
            if (response.statusCode < 300) {
                props.messages("info", "alterar cartão", "cartão " + card.cardCodeId + " alterado.");
                props.hideDialog(true);
            } else {
                props.messages(
                "error",
                "alterar cartão",
                "Error: " + response.statusCode + " " + response?.error
                );
            }
            })
            .catch(error => {
            if (error.request) {
                props.messages("error", "alterar cartão", "PF informar que o server está em baixo");
            } else if (error.response) {
                props.messages("error", "alterar cartão", "response" + error.response);
            } else {
                props.messages("error", "alterar cartão", "message" + error.message);
            }
            props.messages("error", "alterar cartão", "Url: " + error.config.url);
            });
        }
    }
    function validateCard() {
        return card.cardCodeId && card.shortName;
    }

    return (
        <Dialog
        visible={true} modal={true} resizable={false} 
        style={{ width: '450px' }} header={"Cartão Associado "+props.name} className="p-fluid"
        footer={cardDialogFooter}
        closable={false}
        >
        <div className="field">
            <label htmlFor="id">Código Cartão</label>
            {props.new && <InputText placeholder="Código cartão" required autoFocus
            value={card.cardCodeId} onChange={(e) => onInputChange(e, 'cardCodeId', 'upper')}
            className={classNames({ 'p-invalid': !card.cardCodeId })}
            />}
            {!props.new && <InputText id="id" value={card.cardCodeId} disabled />}
        </div>

        <div className="field">
            <label htmlFor="id">Sócio Nrº Cartão</label>
            <InputText placeholder="número sócio" required autoFocus
            value={card.associateId} onChange={(e) => onInputChange(e, 'associateId', 'upper')}
            className={classNames({ 'p-invalid': !card.associateId })}
            />
        </div>

        <div className="field">
            <label>Nome Apelido</label>
            <InputText placeholder="nome apelido" value={card.shortName}
            onChange={(e) => onInputChange(e, 'shortName', 'upper')}
            className={classNames({ 'p-invalid': !card.shortName })}
            required />
        </div>

        <div className="field">
            <label htmlFor="qtyAllowed">Qtd Mensal</label>
            <InputNumber id="qtyAllowed" value={card.qtyAllowed}
            onValueChange={(e) => onInputChange(e, 'qtyAllowed')}
            mode="decimal" integeronly min={-1} max={999999} locale="pt-PT"
            required />
        </div>

        <div className="field">
            <label htmlFor="qtyDayly">Qtd Diária</label>
            <InputNumber id="qtyDayly" value={card.qtyDayly}
            onValueChange={(e) => onInputChange(e, 'qtyDayly')}
            mode="decimal" integeronly min={-1} max={999999}
            required />
        </div>
        </Dialog>
    )
}
