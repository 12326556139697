import React, { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";

import { useAppContext } from "../../lib/contextLib";
// import { onError } from "../../lib/errorLib";


export default function Home() {
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function renderSitesList() {
    return (
        <h3>Estou dentro de Estatísticas!</h3>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>CD Baldios</h1>
        <p>Loading estatísticas from cloud.</p>
      </div>
    );
  }

  function renderSites() {
    return (
      <div className="partners">
        {/* <h3>Your Estatísticas</h3> */}
        <ListGroup>
          {!isLoading && renderSitesList()}
        </ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderSites() : renderLander()}
    </div>
  );
}
