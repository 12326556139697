import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./containers/Home";
import ChangeEmail from "./containers/authentication/ChangeEmail";
import ChangePassword from "./containers/authentication/ChangePassword";
import Login from "./containers/authentication/Login";
import ResetPassword from "./containers/authentication/ResetPassword";
import Settings from "./containers/authentication/Settings";
import Signup from "./containers/authentication/Signup";
import NotFound from "./containers/NotFound";


import ListCards from "./containers/cards/ListCards";
import ListPartners from "./containers/partners/ListPartners";
import ListSites from "./containers/sites/ListSites";
import ListStats from "./containers/statistics/ListStats";
import ListMovs from "./containers/movements/ListMovements";

import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

export default function Routes() {

  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login/reset">
        <ResetPassword />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/settings">
        <Settings />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings/email">
        <ChangeEmail />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings/password">
        <ChangePassword />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/cards">
        <ListCards />
      </AuthenticatedRoute>      
      {/* <AuthenticatedRoute exact path="/cards/new">
        <NewCard />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/cards/:id">
        <Card />
      </AuthenticatedRoute> */}

      <AuthenticatedRoute exact path="/partners">
        <ListPartners />
      </AuthenticatedRoute>
      
      <AuthenticatedRoute exact path="/sites">
        <ListSites />
      </AuthenticatedRoute>
      
      <AuthenticatedRoute exact path="/statistics">
        <ListStats />
      </AuthenticatedRoute>
      
      <AuthenticatedRoute exact path="/transactions">
        <ListMovs />
      </AuthenticatedRoute>
      
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
