import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';

import { validateEmail } from "../../lib/dataTableLib";
import { LocalStorageState } from "../../lib/contextLib";

/* ------------------------------------------------------------------------------------------------
                  new or update partner
------------------------------------------------------------------------------------------------ */
export function DialogPartner(props) {
  const [partnerNew, setPartnerNew] = useState();
  const [partner, setPartner] = useState(props.partner);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setPartnerNew(props.partnerNew);
    setSubmitted(false);
  }, [props.partnerNew]);

  // ----------------------------------------------------------------
  function validatePartner() {
    const _partner = partner;
    return _partner && _partner.street && _partner.zip_code && _partner.zip_local && _partner.fullName && _partner.shortName && _partner.phone && validateEmail(_partner.email); 
  }  
  
  // ----------------------------------------------------------------
  function onInputChange(e, name, options) {
    let val = (e.target && e.target.value) || '';
    let _partner = { ...partner };
    if (options){
      switch (options.toLowerCase()) {
        case 'upper':
          val = val.toUpperCase();
          break;
        case 'lower':
          val = val.toLowerCase();
          break;
        default:
          break;
      }
    }
    _partner[`${name}`] = val;
    setPartner(_partner);
  }
  // ----------------------------------------------------------------
  function onInputMaskChange(e, name) {
    let val = (e.value) || '';
    let _partner = { ...partner };
    _partner[`${name}`] = val;
    setPartner(_partner);
  }
  // ----------------------------------------------------------------
  const partnerDialogFooter = (
    <React.Fragment>
        <Button label="Fechar" icon="pi pi-times" className="p-button-rounded p-button-warning" onClick={() => props.hideDialog(false)} />
        {validatePartner() && <Button label="Salvar" icon="pi pi-check" className="p-button-rounded p-button-danger" onClick={savePartner} />}
    </React.Fragment>
  );  
  // ----------------------------------------------------------------
  async function savePartner () {
    setSubmitted(true);
    const myInit = {
      body: { ...partner, userId: LocalStorageState('UserEmail', 'read') },
    };
    if (partnerNew) {
      await API.post("baldios", "/partners/add", myInit)
      .then(function () {
        props.hideDialog(true);
      })
      .catch(function (error) {
        if (error.request) {
          props.messages("error", "adicionar associado", "PF informar que o server está em baixo");
        } else if (error.response) {
          props.messages("error", "adicionar associado", "response"+error.response);
        } else {
          props.messages("error", "adicionar associado", "message"+error.message);          
        }
        props.messages("error", "adicionar associado", "Url: " + error.config.url);
    });
    } else {
      API.put("baldios", "/partners/" + partner.partnerId, myInit)
      .then(function (result) {
        props.hideDialog(true);
      })
      .catch(function (error) {
        if (error.request) {
          props.messages("error", "guardar associado", "PF informar que o server está em baixo");
        } else if (error.response) {
          props.messages("error", "guardar associado", "response"+error.response);
        } else {
          props.messages("error", "guardar associado", "message"+error.message);          
        }
        props.messages("error", "guardar associado", "Url: " + error.config.url);
      });
    }
  };
  // && <small className="p-error">Número fiscal é obrigatório.</small>
  return (partner &&
    <Dialog 
      visible={true} modal={true} resizable={false} 
      style={{ width: '450px' }} header="Detalhes Associado"
      className="p-fluid" footer={partnerDialogFooter}
      closable={false}
    >
      <div className="field">
        <label htmlFor="id">Número Contribuinte</label>
        {partnerNew && <InputMask id="id" mask="PT999999999" placeholder="PT000000000" required autoFocus
          value={partner.partnerId} onChange={(e) => onInputMaskChange(e, 'partnerId')}
          className={classNames({ 'p-invalid': submitted && !partner.partnerId })}
        />}
        {!partnerNew && <InputText id="id" value={partner.partnerId} disabled />}
      </div>

      <div className="field">
        <label>Nome Completo</label>
        <InputText id="fullName" placeholder="nome completo" value={partner.fullName}
          onChange={(e) => onInputChange(e, 'fullName')}
          className={classNames({ 'p-invalid': submitted && !partner.fullName })}
          required />
      </div>

      <div className="field">
        <label>Nome</label>
        <InputText placeholder="nome apelido" value={partner.shortName}
          onChange={(e) => onInputChange(e, 'shortName', 'upper')}
          className={classNames({ 'p-invalid': submitted && !partner.shortName })}
          required />
      </div>

      <div className="filed">
        <label>Telefone</label>
        <InputMask mask="999 999 999" placeholder="123 456 789"
          value={partner.phone} onChange={(e) => onInputMaskChange(e, 'phone')}
          className={classNames({ 'p-invalid': submitted && !partner.phone })}
          integeronly locale="pt-PT"
          required />
      </div>

      <div className="field">
        <label>Email</label>
        <InputText placeholder="box@sitio.com" value={partner.email}
          onChange={(e) => onInputChange(e, 'email', 'lower')}
          className={classNames({ 'p-invalid': submitted && !partner.email })}
          required />
      </div>

      <div className="field">
        <label >Morada</label>
        <InputTextarea placeholder="morada" value={partner.street}
          onChange={(e) => onInputChange(e, 'street')}
          className={classNames({ 'p-invalid': submitted && !partner.street })}
          required />
      </div>

      <div className="field">
        <InputMask mask="9999-999" placeholder="1234-567"
          value={partner.zip_code} onChange={(e) => onInputMaskChange(e, 'zip_code')}
          integeronly locale="pt-PT"
          className={classNames({ 'p-invalid': submitted && !partner.zip_code })}
          required />
      </div>

      <div className="field">
        <InputText placeholder="localidade" value={partner.zip_local}
          onChange={(e) => onInputChange(e, 'zip_local', 'Upper')}
          className={classNames({ 'p-invalid': submitted && !partner.zip_local })}
          required />
      </div>

      <div className="field">
        <label htmlFor="qtyAllowed">Qtd Mensal</label>
        <InputNumber id="qtyAllowed" value={partner.qtyAllowed}
          onValueChange={(e) => onInputChange(e, 'qtyAllowed')}
          mode="decimal" integeronly min={-1} max={999999} locale="pt-PT"
          required />
      </div>

      <div className="field">
        <label htmlFor="qtyDayly">Qtd Diária</label>
        <InputNumber id="qtyDayly" value={partner.qtyDayly}
          onValueChange={(e) => onInputChange(e, 'qtyDayly')}
          mode="decimal" integeronly min={-1} max={999999}
          required />
      </div>
    </Dialog>
  )
}
