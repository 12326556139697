import React, { useState, useEffect, useRef } from "react";

import { API } from "aws-amplify";

import { Toast } from 'primereact/toast';

import { Transactions } from "../../components/Transactions";

import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import { renderProgressSpinner } from "../../lib/dataTableLib";

export default function Home() {
    const toast = useRef(null);
    const { isAuthenticated } = useAppContext();
    const [movements, setMovements ] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function onLoad() {
        if (!isAuthenticated) {
            return;
        }
        try {
            const movements = await loadMovements();
            setMovements(movements);
        } catch (e) {
            onError(e);
        }
        setIsLoading(false);
        }

        onLoad();
    }, [isAuthenticated]);

    async function loadMovements() {
        const myInit = {
        body: {
            contractId: process.env.REACT_APP_CONTRACT,
        },
        };
        const result = await API.post("baldios", "/movements", myInit);
        return result;
    }

    function renderLander() {
        return (
        <div className="lander">
            <h1>CD Baldios</h1>
            <p>Loading movements from cloud...</p>
        </div>
        );
    }

    function renderMovements() {
        return (
            <div className="movements">
                <Toast ref={toast} />        
                {(!isLoading && <Transactions movs={movements}></Transactions>) || (isLoading && renderProgressSpinner())}
            </div>
        );
    }

    return (
        <div className="Home">
        {isAuthenticated ? renderMovements() : renderLander()}
        </div>
    );
}
