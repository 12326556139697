import React, { useState, useEffect, useRef } from "react";

import { API } from "aws-amplify";

import { Button } from 'primereact/button';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';

import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import {
    // header,
    lockedBody,
    renderProgressSpinner,
} from "../../lib/dataTableLib";

export default function Home() {
    const toast = useRef(null);

    const { isAuthenticated } = useAppContext();
    const [cards, setCards ] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const [ filters, setFilters ] = useState(null);
    const [ globalFilterValue, setGlobalFilterValue] = useState('');



    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }
            try {
                initFilters();   
                const cards = await loadCards();
                setCards(cards);
            } catch (e) {
                onError(e);
            }
            setIsLoading(false);
        }
        onLoad();
    }, [isAuthenticated]);

    async function loadCards() {
        const myInit = {
        body: {
            contractId: process.env.REACT_APP_CONTRACT,
        },
        };
        const result = await API.post("baldios", "/cards", myInit);
        // console.table(result);
        return result;
    }

    const rightToolbarTemplate = () => {
        return (
        <div className="flex justify-content-between">
            <Button type="button" icon="pi pi-filter-slash" label="Limpar" className="p-button-outlined" onClick={clearFilter} />
            <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Palavras a pesquisar" />
            </span>
        </div>
        )
    };
    
    // ****************************************************************
    // for search stuff -  for Datatables
    // ****************************************************************
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters.global.value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const clearFilter = () => {
        initFilters();
    }; 
    const initFilters = () => {
        setFilters({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        setGlobalFilterValue('');
    };

    function renderCardsList() {
        return (
            <>
                <Toast ref={toast} />        

                <Toolbar className="mb-10" right={rightToolbarTemplate}></Toolbar>

                <DataTable value={cards} stripedRows 
                    scrollable scrollHeight="700px"
                    dataKey="cardCodeId" filters={filters}
                    emptyMessage="Não encontramos nenhum cartão."
                >
                    <Column field="cardCodeId" header="Identificação"  align="center"></Column>
                    <Column field="partnerId" header="Associado" align="left"></Column>
                    <Column field="shortName" header="Nome Abreviado" align="left"></Column>
                    <Column field="qtyAllowed" header="Qty Mensal" align="center"></Column>
                    <Column field="qtyDayly" header="Qty Diária" align="center"></Column>
                    <Column field="qtyConsumed" header="Consumido" align="center"></Column>
                    <Column field="qtyFilled" header="Total" align="center"></Column>
                    <Column field="isLocked" header="Status" align="center" body={lockedBody} ></Column>
                </DataTable>
            </>
        );
        // return loadHtml();
    }

    function renderLander() {
        return (
        <div className="lander">
            <h1>CD Baldios</h1>
            <p>Loading associados from cloud.</p>
        </div>
        );
    }

    function renderCards() {
        return (
            <div className="cards">
            {(!isLoading && renderCardsList()) || (isLoading && renderProgressSpinner())}
            </div>
        );
    }

    return (
        <div className="Home">
        {isAuthenticated ? renderCards() : renderLander()}
        </div>
    );
}
