import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";
  
import { Toast } from 'primereact/toast';

import packageJson from '../package.json';
import "./App.css";
import { AppContext, LocalStorageState } from "./lib/contextLib";
import { onError } from "./lib/errorLib";
import Routes from "./Routes";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const history = useHistory();

  useEffect(() => {
    document.title = "IT2E/CDBaldios-" + packageJson.version;
    onLoad();
  }, []);

  async function onLoad() {    
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch (e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    LocalStorageState('UserEmail','destroy');
    history.push("/");
  }

  return (
    !isAuthenticating && (
      <div className="App container">
        <Toast />
        <Navbar>
          <Navbar.Brand className="font-weight-bold text-muted">
            <Link to="/">IT2E</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            {isAuthenticated ? (
              <>
                <div class="navbar-nav mr-auto">
                  <NavLink className="nav-menu" activeStyle={{ fontWeight: "bold", color: "black" }} exact to="/sites">Locais</NavLink>
                  <NavLink className="nav-menu" activeStyle={{ fontWeight: "bold", color: "black" }} exact to="/partners">Associados</NavLink>
                  <NavLink className="nav-menu" activeStyle={{ fontWeight: "bold", color: "black" }} exact to="/cards">Cartões</NavLink>
                  <NavLink className="nav-menu" activeStyle={{ fontWeight: "bold", color: "black" }} exact to="/transactions">Movimentos</NavLink>
                  <NavLink className="nav-menu" activeStyle={{ fontWeight: "bold", color: "black" }} exact to="/statistics">Estatísticas</NavLink>
                </div>
                <div class="navbar-nav ml-auto">
                  <NavLink className="nav-menu" activeStyle={{ fontWeight: "bold", color: "black" }} exact to="/Settings">Setting </NavLink>
                  <Navbar.Collapse className="justify-content-end">
                    <Nav.Link className="nav-menu" onClick={handleLogout}>Logout</Nav.Link>
                  </Navbar.Collapse>
                </div>
              </>
            ) : (
              <div class="navbar-nav ml-auto">
                <NavLink className="nav-menu" activeStyle={{ fontWeight: "bold", color: "black" }} exact to="/Login">Login</NavLink>            
              </div>
            )}
          </Navbar.Collapse>
        </Navbar>
        <ErrorBoundary>
          <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
            <Routes />
          </AppContext.Provider>
        </ErrorBoundary>
      </div>
    )
  );
}

export default App;
