import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

import { Dialog } from 'primereact/dialog';

import { Transactions } from '../../components/Transactions';
import { LocalStorageState } from "../../lib/contextLib";

export function DialogMovements(props) {
  const [movements, setMovements] = useState();
  const [partner] = useState(props.partner);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      loadPartnersMovements();
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  async function loadPartnersMovements() {
    await API.get("baldios", "/partners/movements/"+partner.partnerId, {
      queryStringParameters: {
        contractId: partner.contractId,
        userId: LocalStorageState('UserEmail', 'read'),
      }
    })
      .then(function (response) {
        if (response.data.length) {
          props.messages("info", "leitura movimentos", "movimentos do associado "+partner.shortName+" lidos.");
        } 
        setMovements(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          props.messages("error", "leitura movimentos", "response:"+error.response);
        } else if (error.request) {
          props.messages("error", "leitura movimentos", "PF informar que o server está em baixo");
        } else {
          props.messages("error", "leitura movimentos", "message:"+error.message);          
        }
        props.messages("error", "leitura movimentos", "Url: " + error.config.url);
      });     
  }
  return (
    <div>
        {!loading && <Dialog
            visible={true} modal={true} resizable={false} 
            style={{ width: '1100px' }} className="p-fluid"
            header={"Associado: " + partner.shortName}
            onHide={() => props.hideDialog(false)}
        >
              {movements && <Transactions movs={movements}></Transactions>}
        </Dialog>}
    </div>    
  )
}
