import React, { useState, useEffect, useRef } from "react";

import { API } from "aws-amplify";

import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

import { onError } from "../../lib/errorLib";
import { useAppContext, LocalStorageState } from "../../lib/contextLib";
import {
    // header,
    lockedBody,
    renderProgressSpinner,
} from "../../lib/dataTableLib";
// TODO: transform this into a agnostics component
// import { showMsgToast } from '../../lib/showMsgToast';
import { DialogLogActions } from "./LogActions";
import { DialogMovements } from "./Movements";
import { DialogSite } from './UpdateSite';

export default function Home() {
    const toast = useRef(null);
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [lockedSiteDialog, setLockedSiteDialog ] = useState();
    const [movementsDialog, setMovementsDialog] = useState();
    const [logCommsDialog, setLogCommsDialog] = useState();
    const [sites, setSites] = useState();
    const [site, setSite] = useState();
    const [siteNew, setSiteNew] = useState();
    const [siteDialog, setSiteDialog ] = useState();

    useEffect(() => {
        async function onLoad() {
        if (!isAuthenticated) {
            return;
        }

        try {
            const sites = await loadSites();
            setSites(sites);
        } catch (e) {
            onError(e);
        }

        setIsLoading(false);
        }

        onLoad();
    }, [isAuthenticated, isLoading]);

    async function loadSites() {
        const myInit = {
        body: {
            contractId: process.env.REACT_APP_CONTRACT,
        },
        };
        const result = await API.post("baldios", "/sites", myInit);
        // console.table(result);
        return result;
    }
    // ----------------------------------------------------------------
    //  change status of partner isLocked 
    // ----------------------------------------------------------------
    function lockedSite(data) {
        API.patch("baldios", "/sites/" + site.siteId, {
            body: {
                ...data,
                contractId: site.contractId,
                userId: LocalStorageState('UserEmail', 'read'),
                version: data.version || 0
            }
        })
            .then(() => hideDialog(true))
            .catch(error => {
                if (error.request) {
                showMsgToast("error", "bloquear associados", "PF informar que o server está em baixo");
                } else if (error.response) {
                showMsgToast("error", "bloquear associados", "response "+error.response);
                } else {
                showMsgToast("error", "bloquear associados", "message "+error.message);          
                }
                showMsgToast("error", "bloquear associados", "Url: " + error.config.url);
            });
    }

    // ----------------------------------------------------------------
    function editSite (site) {
        setSiteNew(false);
        setSite({ ...site });
        setSiteDialog(true);
    }
    // ----------------------------------------------------------------
    function confirmLockedSite (site) {
        setSite(site);
        setLockedSiteDialog(true);
    }
    // ----------------------------------------------------------------
    function movementsSite (site) {
        setSite(site);
        setMovementsDialog(true);
    }
    // ----------------------------------------------------------------
    function logCommsSite (site) {
        setSite(site);
        setLogCommsDialog(true);
    }
    // ----------------------------------------------------------------
    // dialogs auxliars 
    function hideDialog(status) {
        setMovementsDialog(false);
        setLogCommsDialog(false);
        setLockedSiteDialog(false);
        setIsLoading(status);
        setSiteNew(false);
        setSiteDialog(false);
    }
    // ----------------------------------------------------------------
    function showMsgToast(type, sum, message) {
        if (type !== "error") {
        toast.current.show([{ severity: type, summary: sum, detail: message, life: 3000 }]);
        } else {
        toast.current.show([{ severity: type, summary: sum, detail: message, sticky: true}]);
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-success mr-2"
                    onClick={() => editSite(rowData)}
                />
                <Button
                    icon="pi pi-lock"
                    className="p-button-rounded p-button-warning"
                    onClick={() => confirmLockedSite(rowData)}
                />
                <Button
                    icon="pi pi-book"
                    className="p-button-rounded p-button-secondary"
                    onClick={() => movementsSite(rowData)}
                />
                <Button
                    icon="pi pi-database"
                    className="p-button-rounded p-button-secondary"
                    onClick={() => logCommsSite(rowData)}
                />
                <Button
                    icon="pi pi-chart-line"
                    className="p-button-rounded p-button-secondary"
                    onClick={() => movementsSite(rowData)}
                />
            </React.Fragment>
        );
    }
    const lockedSiteDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={() =>hideDialog(false)} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={() => lockedSite(site)} />
        </React.Fragment>
    );

    function renderSitesList(sites) {
        return (
            <>
                <Toast ref={toast} />        

                <DataTable value={sites}>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }} frozen={true}></Column>
                    <Column field="siteId" header="Identificação"></Column>
                    <Column hidden field="contractId" header="Contrato"></Column>
                    <Column field="shortName" header="Local"></Column>
                    <Column field="qtyAllowed" header="Qty Mensal" align="right"></Column>
                    <Column field="qtyDayly" header="Qty Diária" align="right"></Column>
                    <Column field="qtyConsumed" body={event => event.qtyConsumed*event.factor} header="Consumido" align="right"></Column>
                    <Column field="lastCounter" header="Contador" align="right"></Column>
                    <Column field="islocked" body={lockedBody} header="Status" align="center"></Column>
                </DataTable>
                
                {siteDialog && <DialogSite
                    hideDialog={hideDialog}
                    site={site}
                    siteNew={siteNew}
                    messages={showMsgToast}
                />}

                {movementsDialog && <DialogMovements
                    hideDialog={hideDialog}
                    site={site}
                    messages={showMsgToast}
                />}    

                {logCommsDialog && <DialogLogActions
                    hideDialog={hideDialog}
                    site={site}
                    messages={showMsgToast}
                />}    

                <Dialog visible={lockedSiteDialog}
                    style={{ width: '450px' }}
                    header="Bloqueio/Desbloqueio" modal
                    footer={lockedSiteDialogFooter}
                    onHide={() => hideDialog(false)}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
                        {site && <span>Tem a certeza que pretende {site.isLocked ?"desbloquear" : "bloquear"} associado <b>{site.shortName}</b>?</span>}
                    </div>
                </Dialog>

            </>
    
        );
    }

    function renderLander() {
        return (
        <div className="lander">
            <h1>CD Baldios</h1>
            <p>Loading sites from cloud...</p>
        </div>
        );
    }

    function renderSites() {
        return (
            <div className="sites">
                {(!isLoading && renderSitesList(sites)) || (isLoading && renderProgressSpinner())}
            </div>
        );
    }

    return (
        <div className="Home">
        {isAuthenticated ? renderSites() : renderLander()}
        </div>
    );
}
