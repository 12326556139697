import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { classNames } from 'primereact/utils';

export const template = {
  layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
  'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
          { label: 10, value: 10 },
          { label: 20, value: 20 },
          { label: 50, value: 50 }
      ];

      return (
          <React.Fragment>
              <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items por pág: </span>
              <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
          </React.Fragment>
      );
  },
  'CurrentPageReport': (options) => {
      return (
          <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
              {options.first} - {options.last} de {options.totalRecords}
          </span>
      )
  }
};
export const renderProgressSpinner = () => {
  return (<div>
    <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="5" fill="var(--surface-ground)" animationDuration="1s"/>
  </div>
)};
export const lockedBody = (event) => {
  return <i className={classNames('pi', {'true-icon pi-thumbs-up': !event.isLocked, 'true-icon pi-ban': event.isLocked})}></i>;
}
export const statusBody = (event) => {
    console.log("event status", event);
  return <i className={classNames('pi', {'true-icon pi-thumbs-up': event.status, 'true-icon pi-ban': !event.status})}></i>;
}
export const integratedBody = (event) => {
    return <i className={classNames('pi', {
        'true-icon pi-map': event.isIntegrated === 'No' || !event.isIntegrated,
        'true-icon pi-wallet': event.isIntegrated === 'Yes' || event.isIntegrated
    })}></i>;
}
export function validateEmail(value) {
  var mailformat = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
  return value.match(mailformat);
}
export function datetimeBody(value) {
    // const _regex = /(?P<ano>[0-9]{4})(?P<mes>[0-9]{2})(?P<dia>[0-9]{2})(?P<hh>[0-9]{2})(?P<mm>[0-9]{2})(?P<ss>[0-9]{2})(?P<ms>[0-9]{6})/;
    const _regex = /([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2}).([0-9]{3})[0-9]*/;
    const _match = value.match(_regex);
    const _databuild = _match[1] + '-' + _match[2] + '-' + _match[3] + ' ' + _match[4] + ':' + _match[5] + ':' + _match[6];
    return (_databuild);
}

export function timeStampStr(value) {
    const sec = Math.trunc(value/1000); // convert value to number if it's string
    let milisecs = value - (sec * 1000);
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds

    // add 0 if value < 10; Example: 2 => 02
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) { seconds = "0" + seconds; }
    if (milisecs < 10) { milisecs = "00" + milisecs; } else if (milisecs < 100) { milisecs = "0" + milisecs; }
    
    return hours + ':' + minutes + ':' + seconds + '.' + milisecs; // Return is HH : MM : SS . MS
}
