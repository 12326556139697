import React, { cloneElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAppContext } from '../lib/contextLib';  

export default function UnauthenticatedRouter(props) {
  const { children, ...rest } = props;
  const { isAuthenticated } = useAppContext();

  function querystring(name, url = window.location.href) {
    const parseName = name.replace(/[[]]/g, "\\$&");
    const regex = new RegExp(`[?&]${parseName}(=([^&#]*)|&|#|$)`, "i");
    const results = regex.exec(url);

    if (!results || !results[2]) {
      return false;
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  const redirect = querystring("redirect");

  return (
    <Route {...rest}>
      {!isAuthenticated ?
        (cloneElement(children, props)) :
        (<Redirect to={redirect ? redirect : "/"} />)
      }
    </Route>
  );
}